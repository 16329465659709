<template>
<van-dialog v-model:show="show" :title="lang[langCode].title" show-cancel-button :show-confirm-button="false" :cancel-button-text="lang[langCode].cancel">
    <van-divider />
    <div class="overflow-y-scroll my-max-h">
        <van-cell v-for="(day, index) in daysList" :key="index" :title="day.dateText" is-link @click="pickDay(day)">
            <template #value>
                <span class="block">{{day.weekText}}</span>
                <van-tag v-if="index === 0" type="success">{{lang[langCode].suggest}}</van-tag>
            </template>
        </van-cell>
    </div>
</van-dialog>
</template>

<script>
import lang from "@/langs/ClassSessionPickerLang.js";
import {
    ref,
    computed
} from "vue";
import {
    Cell,
    Dialog,
    Divider,
    Tag
} from "vant";
import Helpers from "@/logics/Helpers.js";
import StaticDays from "@/static_values/StaticDays.js";
import AddSession from "@/asyncs/AddSession.js";
import GetDays from "@/logics/GetDaysOfWeekInMonth.js";

export default {
    components: {
        [Cell.name]: Cell,
        [Dialog.Component.name]: Dialog.Component,
        [Divider.name]: Divider,
        [Tag.name]: Tag
    },
    props: {
        langCode: {
            type: String,
            default: "zh"
        },
        classId: String,
        classTimes: Array,
        year: Number,
        month: Number //1..12
    },
    setup(props, {
        emit
    }) {
        const show = ref(false);
        const loading = ref(false);
        const showList = function (exclude) {
            exclude.forEach(function (session) {
                const id = session.y + "_" + session.m + "_" + session.d + "_" + session.t;
                delete daysMap[id];
            });
            show.value = true;
        };
        const close = function () {
            show.value = false;
        };

        const daysMap = {};
        GetDays(props.classTimes, props.year, props.month, function (day, slot) {
            const option = {
                y: props.year,
                m: props.month,
                d: day,
                wd: slot.day,
                t: slot.timeStart,
                du: slot.duration,
                id: "",
                dateText: "",
                weekText: ""
            };
            option.dateText = Helpers.makeDateText(option.y, option.m, option.d);
            option.weekText = StaticDays[option.wd][props.langCode] + " " + Helpers.makeClassTimeToText(option.t);
            option.id = option.y + "_" + option.m + "_" + option.d + "_" + option.t;
            daysMap[option.id] = option;
        });

        const daysList = computed(function () {
            return Object.values(daysMap).sort(function (b, a) {
                if (a.y === b.y) {
                    if (a.m === b.m) {
                        if (a.d === b.d) {
                            if (a.t === b.t) {
                                return 0;
                            } else {
                                return b.t - a.t;
                            }
                        } else {
                            return b.d - a.d;
                        }
                    } else {
                        return b.m - a.m;
                    }
                } else {
                    return b.y - a.y;
                }
            });
        });

        const pickDay = function (day) {
            loading.value = true;
            (async () => {
                try {
                    const newSession = await AddSession(props.classId,
                        day.y,
                        day.m,
                        day.d,
                        day.wd,
                        day.t,
                        day.du,
                        0);
                    if (newSession) {
                        emit("sessionAdded", newSession);
                        show.value = false;
                    } else {
                        alert("添加失败！");
                    }
                    loading.value = false;
                } catch (e) {
                    console.log(e);
                    loading.value = false;
                }
            })();
        };

        return {
            lang,
            show,
            showList,
            loading,
            close,
            pickDay,
            daysList
        };
    }
}
</script>

<style scoped>
.my-max-h {
    max-height: 60vh;
    scrollbar-width: none;
}

.my-max-h::-webkit-scrollbar {
    width: 0px;
}

.my-max-h::-webkit-scrollbar-track {
    display: none;
}
</style>
