export default {
    en: {
        title: "Add New Record",
        suggest: "Recommended",
        confirm: "Confirm",
        cancel: "Cancel",
        textYear: "Year",
        textMonth: "Month",
        textDate: "Day",
        textBegin: "Begin Time",
        textHour: "h",
        textMinute: "m",
        textEnd: "End Time",
        textDuration: "Duration",
        minutes: "Minutes",
        textTeacherType: "Teaching Type"
    },
    zh: {
        title: "添加新的课堂记录",
        suggest: "推荐",
        confirm: "确认",
        cancel: "取消",
        textYear: "年",
        textMonth: "月",
        textDate: "日期",
        textBegin: "开始时间",
        textHour: "时",
        textMinute: "分",
        textEnd: "结束时间",
        textDuration: "课堂时长",
        minutes: "分钟",
        textTeacherType: "教课身份"
    }
}