import Helpers from "@/logics/Helpers.js";
import StaticDays from "@/static_values/StaticDays.js";

export default function (sessionList, studentsMap, langCode) {
    sessionList.forEach(function (session) {
        session.title = Helpers.makeDateText(session.y, session.m, session.d) + " " + StaticDays[session.wd][langCode] + " " + Helpers.makeClassTimeToText(session.t);

        session.check = Object.entries(session.check).map(entry => {
            if (!studentsMap[entry[0]]) {
                return {
                    id: entry[0],
                    icon: "unfound.png",
                    a: entry[1].a,
                    showAction: false
                };
            }
            const info = studentsMap[entry[0]];
            return {
                id: entry[0],
                name: info.name,
                icon: info.icon,
                a: entry[1].a,
                showAction: false
            }
        });
    });
}