<template>
    <div class="pt-32 flex flex-wrap" v-if="user">
        <div class="md:w-1/3 w-full mb-12">
            <class-details-card v-if="classDetails" :classDetails="classDetails" :lang-code="langCode"
                :my-theme="myTheme" />
        </div>
        <div class="md:w-2/3 w-full flex flex-col items-center px-4">
            <van-cell-group class="shadow-md w-full" inset>
                <van-collapse v-model="active">
                    <van-collapse-item :title="session.title" :name="index" v-for="(session, index) in sessions"
                        :key="session.sid">
                        <template #value>
                            <van-tag class="mr-4" type="danger" round plain>{{ TeacherTypes[session.tt][langCode]
                                }}</van-tag>
                            <span>{{ (session.du + lang[langCode].mins) }}</span>
                        </template>
                        <div class="flex flex-wrap">
                            <div class="h-8"></div>
                            <div v-for="student in session.check" :key="student.id"
                                class="flex flex-col items-center m-2">
                                <van-popover v-model:show="student.showAction" theme="dark" :actions="actions"
                                    @select="studentAction($event, student, session.sid)">
                                    <template #reference>
                                        <van-image
                                            :class="{ 'att-yes': student.a === 1, 'att-late': student.a === 2, 'att-no': student.a === 3, 'att-fail': student.a === 4 }"
                                            class="cursor-pointer" fit="cover" :radius="10"
                                            :src="Env.userPath + student.icon" width="4rem" height="4rem"
                                            @click="loadStudent(student)" />
                                    </template>
                                </van-popover>
                                <span class="text-xs">{{ student.name }}</span>
                            </div>
                        </div>
                        <div class="h-8 mt-8 mb-4 flex flex-row-reverse gap-4">
                            <van-button class="h-full" square type="danger" :text="lang[langCode].buttonDelete"
                                @click="deleteSession(session, index)" />
                            <van-button class="h-full" square type="warning" text="全部请假"
                                @click="checkInAll(session, 3)" />
                            <van-button class="h-full" square type="danger" text="移除未出席学生"
                                @click="removeAllMissing(session)" />
                        </div>
                    </van-collapse-item>
                </van-collapse>
            </van-cell-group>
            <van-button class="mt-8 w-1/2" type="primary" @click="showDayPicker()">{{ lang[langCode].buttonAddDefault
                }}</van-button>
            <van-button class="mt-8 w-1/2" type="warning" @click="showExtraSession()">{{ lang[langCode].buttonAddAny
                }}</van-button>
        </div>
    </div>
    <session-picker ref="dayPickerRef" v-if="classDetails" :class-id="classDetails.classId"
        :class-times="classDetails.classTimes" :year="year" :month="month" @session-added="addSession($event)"
        :lang-code="langCode" />
    <session-adder ref="sessionAdder" v-if="classDetails" :class-id="classDetails.classId"
        @session-added="addSession($event)" :lang-code="langCode" />
</template>

<script>
import lang from "@/langs/PageClassSessionLang.js";
import {
    ref,
    computed,
    onMounted
} from "vue";
import {
    useStore
} from "vuex";
import {
    useRoute,
    useRouter
} from "vue-router";
import {
    Collapse,
    CollapseItem,
    CellGroup,
    Image as VanImage,
    Popover,
    Button,
    SwipeCell,
    Dialog,
    Toast,
    Tag
} from "vant";
import myTheme from "@/logics/MyVantTheme.js";
import formatSession from "@/logics/FormatSessionData.js";
import Env from "@/logics/Envs.js";
import TeacherTypes from "@/static_values/StaticTeacherTypes.js";
import ClassDetailsCard from "@/components/ClassDetailsCard.vue";
import GetSession from "@/asyncs/GetSession.js";
import LoadClass from "@/asyncs/LoadClass.js";
import LoadSession from "@/asyncs/LoadClassSession.js";
import CheckIn from "@/asyncs/UpdateCheckIn.js";
import CheckInAll from "@/asyncs/UpdateCheckInAll.js";
import DeleteSession from "@/asyncs/DeleteSession.js";
import LoadStudents from "@/asyncs/LoadStudents.js";
import LoadUserInfo from "@/asyncs/LoadUserInfo.js";
import SessionPicker from "@/components/dialog/ClassSessionPicker.vue";
import SessionAdder from "@/components/dialog/ClassSessionAdder.vue";

export default {
    name: "PageClassSession",
    components: {
        [Collapse.name]: Collapse,
        [CollapseItem.name]: CollapseItem,
        [CellGroup.name]: CellGroup,
        [VanImage.name]: VanImage,
        [Popover.name]: Popover,
        [Button.name]: Button,
        [SwipeCell.name]: SwipeCell,
        [Tag.name]: Tag,
        ClassDetailsCard,
        SessionPicker,
        SessionAdder
    },
    setup() {
        Toast.setDefaultOptions({
            className: "break-normal"
        });
        const store = useStore();
        store.commit("showBack");
        store.commit("showMenu");
        const route = useRoute();
        const router = useRouter();
        const year = parseInt(route.params.year);
        const month = parseInt(route.params.month);
        const classDetails = ref(null);
        const sessions = ref([]);
        let students = null;
        onMounted(() => {
            GetSession({
                store,
                router,
                route
            }, async function () {
                if (!store.state.classDetails) {
                    classDetails.value = await LoadClass(route.params.classId);
                    if (!classDetails.value) {
                        return;
                    }
                } else {
                    classDetails.value = store.state.classDetails;
                }
                store.commit("setPageTitle", classDetails.value.className);
                students = await LoadStudents(route.params.classId);
                if (!students) {
                    return;
                }
                sessions.value = await LoadSession(route.params.classId, year, month, true);
                formatSession(sessions.value, students, store.state.lang);
            })
        });

        const active = ref([0]);
        const actions = computed(function () {
            return [{
                text: lang[store.state.lang].attendanceItem1,
                value: 1
            },
            {
                text: lang[store.state.lang].attendanceItem2,
                value: 2
            },
            {
                text: lang[store.state.lang].attendanceItem3,
                value: 3
            },
            {
                text: lang[store.state.lang].attendanceItem4,
                value: 4
            }
            ];
        });
        const studentAction = async function (action, student, sessionId) {
            const ok = await CheckIn(sessionId, student.id, action.value, student.a);
            if (ok) {
                student.a = action.value;
            } else {
                alert("更新记录失败！");
            }
        };
        const loadStudent = async function (student) {
            if (!student.name) {
                const userLeft = await LoadUserInfo(student.id);
                Toast("此用户已离开本课程: " + userLeft.profile.showName);
                student.icon = userLeft.profile.icon;
                student.name = userLeft.profile.showName;
            }
        };

        const dayPickerRef = ref(null);
        const showDayPicker = function () {
            dayPickerRef.value.showList(sessions.value);
        };
        const addSession = function (session) {
            formatSession([session], students, store.state.lang);
            sessions.value.unshift(session);
        }
        const deleteSession = async function (session, index) {
            try {
                await Dialog.confirm({
                    title: lang[store.state.lang].dialogDeleteTitle,
                    message: lang[store.state.lang].dialogDeleteMessage,
                    confirmButtonColor: "#ee0a24",
                    confirmButtonText: lang[store.state.lang].confirm,
                    cancelButtonText: lang[store.state.lang].cancel
                });
            } catch (e) {
                console.log(e);
                return;
            }
            const error = await DeleteSession(session.sid);
            if (error) {
                Toast.fail(error);
            } else {
                Toast.success(lang[store.state.lang].dialogDeleteDone);
                sessions.value.splice(index, 1);
            }
        };

        const sessionAdder = ref(null);
        const showExtraSession = function () {
            sessionAdder.value.showAdder(year, month, new Date().getDate());
        };

        const checkInAll = async function (session, check) {
            const result = session.check.reduce((acc, item) => {
                acc[item.id] = { a: check };
                return acc;
            }, {});
            const ok = await CheckInAll(session.sid, result);
            if (ok) {
                session.check.forEach(function (student) {
                    student.a = check;
                });
            }
        };
        const removeAllMissing = async function (session) {
            const newSessionList = [];
            const result = session.check.reduce((acc, item) => {
                if (item.a === 1 || item.a === 2) {
                    acc[item.id] = { a: item.a };
                    newSessionList.push(item);
                }
                return acc;
            }, {});
            const ok = await CheckInAll(session.sid, result);
            if (ok) {
                session.check = newSessionList;
            }
        };

        return {
            lang,
            langCode: computed(() => store.state.lang),
            Env,
            myTheme,
            classDetails,
            sessions,
            active,
            actions,
            studentAction,
            addSession,
            deleteSession,
            dayPickerRef,
            showDayPicker,
            year,
            month,
            showExtraSession,
            sessionAdder,
            TeacherTypes,
            user: computed(() => store.state.user),
            loadStudent,
            checkInAll,
            removeAllMissing
        };
    }
};
</script>
