import axios from "axios";
import Env from "@/logics/Envs.js";

export default async function (sessionId, studentId, check, fromCheck) {
    try {
        const params = { sessionId, studentId, check, fromCheck };
        const res = await axios.get(Env.apiPath + "checkInSession", { params });
        if (res.data.error) {
            alert(res.data.error);
            return null;
        }
        return true;
    } catch (err) {
        alert(err);
    }
}