<template>
<van-dialog v-model:show="show" :title="lang[langCode].title" show-cancel-button show-confirm-button @confirm="onConfirm" :cancel-button-text="lang[langCode].cancel" :confirm-button-text="lang[langCode].confirm">
    <van-divider />
    <div class="overflow-y-scroll my-max-h">
        <van-field :label="lang[langCode].textYear" :model-value="year" readonly />
        <van-field :label="lang[langCode].textMonth" :model-value="month" readonly />
        <van-field :label="lang[langCode].textDate">
            <template #input>
                <van-stepper v-model="today" step="1" integer min="1" max="31" />
            </template>
        </van-field>
        <van-field :label="lang[langCode].textBegin">
            <template #input>
                <div class="flex flex-col">
                    <div class="flex">
                        <div class="w-12">{{startHour}} {{lang[langCode].textHour}}</div>
                        <van-stepper v-model.number="startHour" step="1" integer min="1" max="12" :show-input="false" @change="computeTime()" />
                    </div>
                    <div class="mt-2 flex">
                        <div class="w-12">{{startMin}} {{lang[langCode].textMinute}}</div>
                        <van-stepper v-model.number="startMin" step="5" integer min="0" max="55" :show-input="false" @change="computeTime()" />
                    </div>
                </div>
                <van-button class="ml-8" type="success" size="small" @click="changeHalf()">{{half}}</van-button>
            </template>
        </van-field>
        <van-field :label="lang[langCode].textEnd">
            <template #input>
                <div class="flex flex-col">
                    <div class="flex">
                        <div class="w-12">{{endHour}} {{lang[langCode].textHour}}</div>
                        <van-stepper v-model.number="endHour" step="1" integer min="1" max="12" :show-input="false" @change="computeTime()" />
                    </div>
                    <div class="mt-2 flex">
                        <div class="w-12">{{endMin}} {{lang[langCode].textMinute}}</div>
                        <van-stepper v-model.number="endMin" step="5" integer min="0" max="55" :show-input="false" @change="computeTime()" />
                    </div>
                </div>
                <van-button class="ml-8" type="success" size="small" @click="changeHalf(true)">{{endHalf}}</van-button>
            </template>
        </van-field>
        <van-field :label="lang[langCode].textDuration" :model-value="(duration + ' ' + lang[langCode].minutes)" readonly />
        <van-field :label="lang[langCode].textTeacherType" class="mb-4">
            <template #input>
                <van-button type="danger" size="small" @click="changeTeacherType()">{{TeacherTypes[teacherType][langCode]}}</van-button>
            </template>
        </van-field>
    </div>
</van-dialog>
</template>

<script>
import lang from "@/langs/ClassSessionAdderLang.js";
import {
    ref
} from "vue";
import {
    Dialog,
    Divider,
    Field,
    Stepper,
    Button
} from "vant";
import AddSession from "@/asyncs/AddSession.js";
import TeacherTypes from "@/static_values/StaticTeacherTypes.js";

export default {
    components: {
        [Dialog.Component.name]: Dialog.Component,
        [Divider.name]: Divider,
        [Field.name]: Field,
        [Stepper.name]: Stepper,
        [Button.name]: Button
    },
    props: {
        langCode: {
            type: String,
            default: "zh"
        },
        classId: String
    },
    setup(props, {
        emit
    }) {
        const show = ref(false);
        const year = ref(0);
        const month = ref(0);
        const today = ref(1);
        const startHour = ref(5);
        const startMin = ref(0);
        const duration = ref(45);
        const half = ref("PM");
        const endHalf = ref("PM");
        const endHour = ref(5);
        const endMin = ref(45);
        const showAdder = function (y, m, d) {
            year.value = y;
            month.value = m;
            today.value = d;
            show.value = true;
        };
        const getTimeValue = function (hour, min, half) {
            let value = hour.value * 60 + min.value;
            if (half.value === "PM") {
                if (hour.value !== 12) {
                    value += 60 * 12;
                }
            } else {
                //AM
                if (hour.value === 12) {
                    value -= 12 * 60;
                }
            }
            return value;
        };
        const computeTime = function () {
            let start = getTimeValue(startHour, startMin, half);
            let end = getTimeValue(endHour, endMin, endHalf);

            if (start > end) {
                end = start;
                endHour.value = startHour.value;
                endMin.value = startMin.value;
                endHalf.value = half.value;
            }
            duration.value = end - start;
        };
        const changeHalf = function (end) {
            if (end) {
                if (endHalf.value === "AM") {
                    endHalf.value = "PM";
                } else {
                    endHalf.value = "AM";
                }
            } else {
                if (half.value === "AM") {
                    half.value = "PM";
                } else {
                    half.value = "AM";
                }
            }
            computeTime();
        };
        const onConfirm = async function () {
            let time = startHour.value * 100 + startMin.value;
            if (half.value === "PM" && startHour.value < 12) {
                time += 1200;
            }
            if (half.value === "AM" && startHour.value >= 12) {
                time += -1200;
            }
            const date = new Date();
            date.setFullYear(year.value);
            date.setMonth(month.value - 1);
            date.setDate(today.value);

            const newSession = await AddSession(
                props.classId,
                year.value,
                month.value,
                today.value,
                date.getDay(),
                time,
                duration.value,
                teacherType.value);
            if (newSession) {
                emit("sessionAdded", newSession);
            }
        };

        const teacherType = ref(1);
        const changeTeacherType = function () {
            if (teacherType.value >= 3) {
                teacherType.value = 1;
                return;
            }
            teacherType.value += 1;
        };

        return {
            lang,
            show,
            showAdder,
            year,
            month,
            today,
            startHour,
            startMin,
            endHour,
            endMin,
            duration,
            half,
            endHalf,
            changeHalf,
            onConfirm,
            computeTime,
            TeacherTypes,
            teacherType,
            changeTeacherType
        };
    }
}
</script>

<style scoped>
.my-max-h {
    max-height: 60vh;
    scrollbar-width: none;
}

.my-max-h::-webkit-scrollbar {
    width: 0px;
}

.my-max-h::-webkit-scrollbar-track {
    display: none;
}
</style>
