export default {
    en: {
        title: "Add New Record",
        suggest: "Recommended",
        cancel: "Cancel"
    },
    zh: {
        title: "添加新的课堂记录",
        suggest: "推荐",
        cancel: "取消"
    }
}