import axios from "axios";
import Env from "@/logics/Envs.js";

export default async function (classId, year, month, day, weekday, time, duration, teacherType) {
    try {
        const toSend = new FormData();
        toSend.append("classId", classId);
        toSend.append("year", year);
        toSend.append("month", month);
        toSend.append("day", day);
        toSend.append("weekday", weekday);
        toSend.append("time", time);
        toSend.append("duration", duration);
        toSend.append("teacherType", teacherType);
        const res = await axios.post(Env.apiPath + "addSession", toSend);
        if (res.data.error) {
            alert(res.data.error);
            return null;
        }
        return res.data.added;
    } catch (err) {
        alert(err);
    }
}