export default {
    en: {
        confirm: "Confirm",
        cancel: "Cancel",
        title: "Lessons",
        buttonAddDefault: "Record Scheduled Lesson",
        buttonAddAny: "Record Random Lesson",
        buttonDelete: "Delete",
        mins: "Mins",
        attendanceItem1: "Present",
        attendanceItem2: "Tardy",
        attendanceItem3: "Excused",
        attendanceItem4: "Unexcused",
        dialogDeleteTitle: "Delete Record",
        dialogDeleteMessage: "Are you sure to delete this record?",
        dialogDeleteDone: "Record Deleted"
    },
    zh: {
        confirm: "确认",
        cancel: "取消",
        title: "课堂",
        buttonAddDefault: "添加默认课堂记录",
        buttonAddAny: "自由添加课堂记录",
        buttonDelete: "删除记录",
        mins: "分钟",
        attendanceItem1: "出席",
        attendanceItem2: "迟到",
        attendanceItem3: "请假",
        attendanceItem4: "旷课",
        dialogDeleteTitle: "移除课堂记录",
        dialogDeleteMessage: "确定要移除此记录吗？",
        dialogDeleteDone: "已删除成功"
    }
}